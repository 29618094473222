.appoint-container-box__items {
    display: flex;
    flex-direction: column;
    margin: 8% auto;
    background: transparent;
    width: 30%;
    border: none;
    border-radius: 12px;
    background-color: #fff;   
}

.appoint-container-box__items  .page-title {
    float: left;
    margin-left: 0;
    margin-top: 20px;
    text-align: start;
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    font-weight: 600;   
    margin-bottom: 50px;
}


.appoint-container-box__items  .row {
   display: flex;
   flex-direction: row;
    border-radius: 4px;
    justify-content: center;
  
}

.appoint-container-box__items .right-content {
    display: flex;
    flex-direction: column;
    padding: 8px;
    width: 100%;
  
}
.appoint-container-box__items .left-content {
    display: flex;
    flex-direction: column;
    width: 100%;
   
}


.appoint-item__contents {
    width: 35%;
    padding: 0px;
    border-radius: 8px;
    border: none;
    padding: 22px;
    margin: 5% auto;
    background: #ebe9eb;
}


.sized-box {
    height: 55px;
}

.appoint-item__contents .form__input {
    display: flex;
    flex-direction: column;
    padding: 8px;
    align-items: flex-start;
}

.appoint-item__contents .form__input span {
    font-size: 16px;
    margin-bottom: 5px !important;
}

.appoint-item__contents .form__input button {
    margin-top: 20px !important;
    color: #fff !important;
    font-size: 18px !important;
    font-weight: 600;
    background-color: #1B2580  !important;
}

#form-btn-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


#login-form__input1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


@media (max-width: 375px)  { 
    .appoint-item__contents {
        width: 100%;
    }
    .container-box__items {
        height: 100%;
        
    }
    .container-box__items-middle {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
    }


    .landing-page-form .appoint-container-box__items {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        border: none;
        border-radius: none;
        
    }

    .row {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

}

@media (max-width: 600px) { 
    .appoint-item__contents {
        width: 100%;
    }
    .container-box__items {
        height: 100%;
    }
    .container-box__items-middle {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
    }


    .landing-page-form .appoint-container-box__items {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        border: none;
        border-radius: none;
        
    }

    .row {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

}

.appoint-item__content-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
}

.appoint-item__content-top span {
    align-items: center;
    font-weight: 600;
}

.form__input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.approved-row {
    background-color: #ffe6e6;
}