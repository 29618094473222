.send-bulk-sms-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.send-bulk-sms-container__box {
    width: 50%;
}

.send-bulk-sms-container__box__item-box {
    width: 100%;
    padding: 18px;
}

.send-bulk-sms-container__box__item-input {
    width: 100% !important;
}

.radio-group-vertical {
    display: block;
  }
  
.radio-group-vertical .ant-radio-wrapper {
    display: block;
    text-align: left;
}