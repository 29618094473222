.App {
  text-align: center;
  background-color: #f4f4f4;
  font-family: "Poppins", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.alert-message {
  width: 80%;
  margin: 0 auto;
  border: 1px solid rgb(240, 179, 179);
  border-radius: 8px;
  padding: 10px;
  background: rgb(254,  244, 244);
  margin-bottom: 30px;
}

.tab-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 20px;
  margin-top: 10px;
}

.active-tab {
  padding-left: 12px; 
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 16px;
  border-bottom: 3px solid #000000;
  margin-bottom: 0px;
  margin-left: 10px;
}

.active-tab:hover {
 cursor: pointer;
}

.active-tab span {
  color: #000000;
  font-weight: 500;
}


.tab-btn {
  font-weight: 600;
  padding-left: 12px; 
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 16px;
  border-bottom: 3px solid none;
  margin-bottom: 0px;
  margin-left: 10px;
}

.tab-btn span {
  color: #686661;
  font-weight: 500;
}

.tab-btn:hover {
  cursor: pointer;
}

.ant-input:focus {
  border-color: #262d30 ;
  box-shadow: 0 0 0 0.1px #262d30;
}


.ant-input:hover {
  border-color: #262d30 ;
  box-shadow: 0 0 0 0.1px #262d30;
}

.container-back-btn {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  border: none !important;
}

.container-back-btn  .icon {
  color: #333 !important;
  align-self: center;
}

.container-back-btn  .btnIcon {
  background: transparent !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.container-back-btn   .back-btn-text {
  color: #333;
  font-size: 14px; 
  font-weight: 300;
  align-self: center;
  margin-left: -30px;

}

/* 
.ant-btn:focus {
  background-color: #262d30;
  border-color: #262d30;
  color: #fff;
}

.ant-btn:hover {
  background-color: #262d30eb !important;
  border-color: #262d30 !important;
  color: #fff !important;
}

.ant-btn {
  background-color: #262d30;
  border-color: #262d30;
  color: #fff;
  width: 100%;
} */