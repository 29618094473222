.profile-container {
    width: 100%;
    padding: 8px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.align-left {
    float: left;
    width: 40%;
}

.align-left__box {
    padding: 8px;
    width: 90%;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0% auto;
    border-radius: 8px;
    border: 1px solid #dddbdb;
}

.over-shape-label {
    display: flex;
    padding: 5px;
    margin-top: 10px;
    flex-direction: column;
}

.letters {
    font-size: 50px;
    font-weight: 900;
    align-self: center;
}

.over-shape-label span {
    font-size: 16px;
    font-weight: 400;
}

.align-right {
    float: right;
    width: 60%;
}

.align-right__box {
    padding: 22px;
    margin: 0% auto;
    display:flex;
    flex-direction: column;
    background: #FFF;
    border-radius: 8px;
    border: 1px solid #dddbdb;
}

.align-right__box-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;

}

.left-label {
    font-size: 14px;
    font-weight: 600;
}

.right-label {
    font-weight: 400;
}

.align-right__box--title {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    margin: 10px;
}


.align-middle__box-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;

}

.align-middle__box--title {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    margin: 20px;
    float: left;
    width: 100%;
}

.align-right__box--message {
    font-weight: 300;
    text-align: left;
    margin: 10px;
}

.align-right-tab {
    padding: 8px;
    margin: 0% auto;
    display:flex;
    flex-direction: row;
    background: #FFF;
    border-radius: 4px;
    border: 1px solid #dddbdb;
    margin-bottom: 10px;
    justify-content: flex-start;

}

.transparent-btn {
    background: transparent !important;
    border: none !important;
    color: #333 !important;
    line-height: none !important;
    border-radius: none !important;
    box-shadow: none !important;
    font-size: 16px;
    margin-right: 20px;
}

.update-button {
    float: left;
    margin-top: 20px;
    text-align: center !important;
    margin-right: 30px !important;
    border: 2px solid #262D30 !important;
    color: #fff !important;
    background: #282936 !important;
}

.align-right__box-form {
    margin-top: 20px;
    width: 50%;
    margin: 5px;
}

.align-right__box-form .form__input {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 10px;
}

.apply-change-button {
    background: #262d30; 
    color: #fff; 
    float: left; 
    width: 100%;
    margin-top: 20px;
}

.custom-modal-btn-group .form__input {
    margin-top: 10px;
}

.apply-change-button:focus {
    border: none !important;
    color: #fff !important;
}

.apply-change-button:hover {
    border: 1px solid #4f4f4fb9 !important;
    color: #333333b9 !important;
}

.align-middle__box {
    padding: 20px;
    margin: 0% auto;
    display:flex;
    flex-direction: column;
    align-items: center;
    background: #FFF;
    border-radius: 8px;
    border: 1px solid #dddbdb;
    width: 40%;
}


.box-size {
    width: 100% !important;
}

.align-middle__box-form {
    margin-top: 20px;
    width: 80%;
    margin: 5px;
}

.align-middle__box-form .form__input {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 10px;
}
