#black-content__row {
    height: 50px;
    border-bottom: 1px solid #dedede;
    background: #f5f5f5;
}

  
#black-content__row .right-content .right-content__row a {
    /* color: #fff; */
}

#static-nav-bar-title {
    color: #fff;
    font-size: 20px !important;
    margin-left: 0px;
}

.nav-bar_container-row {
    border-bottom: 1px solid #D8D8D8;
    height: 60px;
}   

.popup-btn span{
   margin-left: 10px;
   margin-top: 0px;
}