.invoice-content {
  padding: 24px;
}

.invoice-header {
  margin-bottom: 24px;
}

.text-right {
  text-align: right;
}

.mt-2 {
  margin-top: 8px;
}

.invoice-details {
  background-color: #fafafa;
}

.invoice-table th {
  background-color: #fafafa !important;
}

.total-section {
  padding: 16px;
  background-color: #fafafa;
  border-radius: 4px;
}

.invoice-footer {
  text-align: center;
  margin-top: 24px;
}

/* Print-specific styles */
@media print {
  @page {
    size: A4;
    margin: 15mm;
  }

  body * {
    visibility: hidden;
  }

  .invoice-content,
  .invoice-content * {
    visibility: visible;
  }

  .invoice-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0 !important;
    font-size: 12pt !important;
    line-height: 1.3 !important;
  }

  .invoice-header {
    margin-bottom: 20pt !important;
  }

  .invoice-header h2 {
    font-size: 24pt !important;
    margin-bottom: 10pt !important;
  }

  .company-details {
    line-height: 1.4 !important;
  }

  .company-details .ant-typography {
    display: block;
    margin-bottom: 2pt !important;
  }

  .invoice-number {
    font-size: 14pt !important;
    margin-bottom: 4pt !important;
  }

  .invoice-details {
    margin: 15pt 0 !important;
  }

  .invoice-table {
    margin: 15pt 0 !important;
    width: 100% !important;
  }

  .ant-table {
    font-size: 11pt !important;
  }

  .ant-table-thead > tr > th {
    background-color: white !important;
    border-bottom: 1pt solid #000 !important;
    padding: 8pt !important;
    font-weight: bold !important;
  }

  .ant-table-tbody > tr > td {
    padding: 8pt !important;
    border-bottom: none !important;
  }

  .ant-table-cell {
    background: none !important;
    border: none !important;
  }

  .total-section {
    margin-top: 10pt !important;
    text-align: left !important;
    border: none !important;
  }

  .invoice-footer {
    margin-top: 20pt !important;
    text-align: left !important;
    font-size: 11pt !important;
  }

  .ant-modal-footer,
  .print-hide,
  .ant-tag,
  .status-tag {
    display: none !important;
  }

  * {
    color: black !important;
    background: none !important;
    border-color: black !important;
  }

  .ant-table-thead th {
    font-weight: bold !important;
    text-align: left !important;
  }

  .ant-table-cell {
    text-align: left !important;
  }

  .ant-table-cell-align-right {
    text-align: right !important;
  }

  .ant-card,
  .ant-table {
    box-shadow: none !important;
    background: none !important;
  }
}
