@media print {
  body * {
    visibility: hidden;
  }

  .coupon-printable,
  .coupon-printable * {
    visibility: visible;
  }

  .coupon-printable {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: white;
  }

  .no-print {
    display: none !important;
  }

  .coupon-header,
  .coupon-footer {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .coupon-table th,
  .coupon-table td {
    border: 1px solid #000 !important;
  }
}

.coupon-container {
  font-family: "Arial", sans-serif;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 24px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 0 auto;
}

.coupon-header {
  text-align: center;
  margin-bottom: 16px;
}

.coupon-header h1 {
  margin: 0 0 8px 0;
  font-size: 24px;
  color: #1d1d1d;
}

.coupon-header h3 {
  margin: 4px 0;
  font-size: 16px;
  font-weight: normal;
  color: #595959;
}

.coupon-content {
  margin-bottom: 20px;
}

.coupon-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 16px;
}

.coupon-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.coupon-label {
  font-weight: bold;
  color: #595959;
  margin-bottom: 4px;
}

.coupon-value {
  color: #1d1d1d;
  font-size: 16px;
}

.coupon-balance {
  font-weight: bold;
  font-size: 18px;
  color: #1890ff;
}

.coupon-table-container {
  margin-top: 20px;
}

.coupon-table-container h3 {
  margin-bottom: 12px;
  font-size: 16px;
}

.coupon-table {
  width: 100%;
}

/* Make sure table headers print with background */
.coupon-table .ant-table-thead > tr > th {
  background-color: #f0f0f0 !important;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

.coupon-footer {
  text-align: center;
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid #f0f0f0;
  color: #595959;
}

.coupon-footer p {
  margin: 4px 0;
}
