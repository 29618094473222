.wallet-management-card {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03), 0 2px 4px rgba(0, 0, 0, 0.03),
    0 4px 8px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  background-color: #f5f7fa;
}

.summary-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.wallet-statistic .ant-statistic-title {
  color: #8c8c8c;
  font-size: 14px;
  margin-bottom: 8px;
}

.wallet-statistic .ant-statistic-content {
  color: #262d30;
  font-size: 24px;
  font-weight: 600;
}

.table-container {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.wallet-table .ant-table-thead > tr > th {
  background-color: #fafafa;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #f0f0f0;
}

.wallet-table .ant-table-tbody > tr:hover > td {
  background-color: #f5f7fa;
}

.wallet-table .ant-empty {
  padding: 40px 0;
}

.ant-empty-normal {
  margin: 32px 0;
}
