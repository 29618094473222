@import url("colors.css");

.second-white-color {
  background: var(--second-white-color);
}

.align-text-left {
  align-self: left;
}

.primary-background-color {
  background: var(--primary-color);
}

.secondary-background-color {
  background: var(--secondary-color);
}

.black-background-color {
  background: var(--black-color) !important;
}

.transparent-background-color {
  background: transparent !important;
  color: var(--black-color);
  font-weight: 600;
}

.transparent-background-color:hover {
  color: var(--black-color) !important;
}

.text-black-color {
  color: var(--black-color) !important;
}

.red-text-color {
  color: var(--red-color);
}

.white-text-color {
  color: var(--white-color) !important;
}

.white-background {
  background: var(--white-color) !important;
}

.default-grey-color {
  color: #777677;
}

.bold-text {
  font-weight: bold;
}

.text-font-size {
  font-size: var(--font-size-16);
}

.custom-modal-footer-space-bt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  padding-right: 8px;
}

.display-row-items-space-bt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.display-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
}

.separator-container {
  height: 0.5px;
  width: 100%;
  background: var(--secondary-color);
}

.padding-tiny {
  padding: 5px;
}

.padding-small {
  padding: 8px;
}

.padding-regular {
  padding: 16px;
}

.margin-top-tiny {
  margin-top: 5px;
}

.margin-top-min-8 {
  margin-top: -10px;
}

.margin-top-small {
  margin-top: 8px;
}

.margin-top-regular {
  margin-top: 16px;
}

.margin-bottom-tiny {
  margin-top: 5px;
}

.margin-bottom-small {
  margin-top: 8px;
}

.margin-bottom-regular {
  margin-top: 16px;
}

/* .selected-table-row {
  background-color: #e6f7ff;
}

.selected-table-row:hover > td {
  background-color: #bae7ff !important;
} */

/* Custom styling for selected table rows */
.selected-table-row {
  background-color: #e6f7ff; /* Light blue background */
}

/* Style for approved rows to visually indicate they can't be selected */
/* .approved-table-row {
  background-color: #f6ffed;
  color: rgba(0, 0, 0, 0.65);
} */

/* Override the hover effect to maintain selection color */
/* .selected-table-row:hover > td {
  background-color: #bae7ff !important;
} */

/* Override hover for approved rows */
/* .approved-table-row:hover > td {
  background-color: #d9f7be !important;
} */

/* Styling for disabled checkbox */
.ant-checkbox-disabled + .ant-checkbox-inner {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
