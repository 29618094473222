.wallet-details-card {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03), 0 2px 4px rgba(0, 0, 0, 0.03),
    0 4px 8px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  height: 100%;
}

.inmate-summary-card {
  background-color: #fafafa;
  border-radius: 6px;
}

.wallet-statistic .ant-statistic-title {
  color: #8c8c8c;
  font-size: 14px;
  margin-bottom: 8px;
}

.wallet-statistic .ant-statistic-content {
  color: #262d30;
  font-size: 24px;
  font-weight: 600;
}

.positive-amount .ant-statistic-content {
  color: #52c41a;
}

.negative-amount .ant-statistic-content {
  color: #f5222d;
}

.transactions-card {
  border-radius: 6px;
}

.transactions-card .ant-card-head {
  border-bottom: 1px solid #f0f0f0;
  padding: 0 16px;
}

.transactions-card .ant-card-head-title {
  font-size: 16px;
  font-weight: 500;
}

.transactions-table .ant-table-thead > tr > th {
  background-color: #f5f5f5;
  color: #595959;
  font-weight: 500;
}

.transactions-table .ant-pagination {
  margin-top: 16px;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
  font-weight: 500;
}

.ant-picker {
  border-radius: 4px;
}

.ant-space-align-center {
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .ant-descriptions-bordered {
    width: 100%;
  }
}
