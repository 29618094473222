.nav-bar__container {
    width: 100%;
    padding: 0;
    margin: 0;
}

.nav-bar__container .nav-bar_container-row {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
}

.nav-bar_container-row .left-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
}
.nav-bar_container-row .right-content {
    display: inherit;
    flex-direction: inherit;

}

.nav-bar_container-row .right-content__row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}


.home-right-content {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    justify-content: flex-end !important;
}

 .Icon-group-home {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: #F7F1F0 !important;
    padding: 15px;
    margin-top: 0px;
    justify-content: flex-start;
    margin-left: 10px;
}

.Icon-group-home i {
    margin-left: 30px;
}

.Icon-group-home span {
    font-weight: 500;
    align-self: center;
    margin-left: 15px;
}

.Icon-group-home span:hover {
    color: #FBE8A6 !important;
}



.Icon-group {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: #F7F1F0 !important;
    padding: 15px;
    margin-top: 0px;
    justify-content: flex-start;
    width: 100%;
}

.Icon-group i {
    margin-left: 30px;
}

.ActiveButton {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: #FBE8A6 !important;
    padding: 15px;
    font-weight: 600;
}

.ActiveButton span {
    color: #FBE8A6 !important;
    margin-left: 15px;
    align-self: center;
}

.Icon-group span {
    color: #FBE8A6;
    font-weight: 500;
    align-self: center;
    margin-left: 15px;
}

.Icon-group span:hover {
    color: #dedede;
}

.Icon-group i {
    margin-left: 6px;
    font-weight: 300;
}

.popup-btn {
    display: flex;
    flex-direction: column;

}

.popup-btn  a {
  width: 100%;
  margin: 5px;
  display: flex;
  flex-direction: row;
} 



.left-content__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px 5px 5px;
}

.left-content .btn-group {
    align-self: center;
    background: none;
    display: flex;
    flex-direction: row;
    justify-content:flex-end;
}

.btn-group a {
  color: #E4C580;
  font-size: 16px;
  padding-bottom: 12px;
  margin-left: 30px;
  font-weight: 600;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  background:none;
  border-radius: 0px;
}

.btn-group a:hover {
    cursor: pointer;
    color: #dedede;
  }

.left-content__row a h2 {
    color: #fff;
    align-self: center;
    margin-top: 30px;
    margin-left: 0px;
    width: 200px;
}

.nav-bar__container-mobile-col {
    display: none;
}

.nav-bar_container-row .mob-view-btn {
    display: none;
}

.nav-bar__container-mobile {
    display: none;
}

@media (max-width: 600px) {
    .nav-bar_container-row .mob-view-btn {
        display: flex;
    }
    
    .nav-bar__container-mobile {
        display: flex;
        height: 100%; 
        width: 0; 
        position: fixed; 
        z-index: 1; 
        top: 0; 
        left: 0;
        background: #212121;
        overflow-x: hidden;
        transition: 0.5s; 
        display: flex;
        flex-direction: column;
        
    }

    .mob-view-btn {
        float: left;
        left: 0;
        margin-left: 0;
        width: 100%;
        margin-top: 0px;
        padding: 8px;
        
    }

    .mob-view-btn button {
        float: left;
        border: none;
        background: transparent;
        color: #f5f5f5;
    }


   
    .left-content__row  a {
        display: none;
    }

    .right-content {
        display: none;
    }

    .nav-bar_container-row .left-content__row {
        display: none;
    }

    .nav-bar__container-mobile-col {
        display: flex;
        flex-direction: column;
        padding: 8px;

    }

   .nav-bar_container-row .right-content {
    display: none;
   }
}