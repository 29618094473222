.footer__items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #303336;
    display: flex;
    flex-direction: column;
}

.footer__items span {
    font-size: 16px;
    color: #fff;
    background: #212121;
    padding: 16px;
}

.footer__items-top {
    width:  100%;
    padding: 18px;
    margin: 0px;
}

.footer-card__box {
    margin: 0 auto;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.footer-card {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 300px;
    
}

.footer-card__title {
    width: 100%;
    padding: 5px;
    text-align: center;
    margin-bottom: 10px;
}

.footer-card__title span {
    color:#d2d2d2;
    font-weight: 600;
    font-size: 18px;
    border-radius: 8px;
    background: transparent;
}

.footer-card__btn-group {
    display: flex;
    flex-direction: column;
    margin-Top: 10px;
}

.footer-card__btn-group a {
    text-align: center;
    padding: 5px;
    color: #fff;
}

