#container-box {
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.video-container-box__items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10% auto;
    width: 60%;
}

.video-container__content {
    width: 60%;
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
}


.video-container__content span {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.video-item__contents {
    width: 40%;
    padding: 8px;
    justify-content: center;
}

.video-item__content-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 170px;
    padding: 16px;
    width: 70%;
}

.video-form__input {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 5px;
}

.video-title {
    font-weight: 600;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 18px;
    text-align: left;
}

.video-form__input button {
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    margin-top: 20px !important;
    background: var(--primary-color) !important;
}


 .v-round-btn {
    border-radius: 4% !important;
    width: 300px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
 }

 .v-round-btn-icon {
    margin-right: 10px;
 }

.video-form__input span {
    font-size: 16px ;
    margin-bottom: 10px;
}

.video-form__input a {
    text-decoration: underline;
    font-size: 16px;
    margin-top: 60px;
}

.video-container__content-video {
    height: 300px;
    width: 75%;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    background: var(--primary-color);
    padding: 0px;
}

.landing-camera {
    width: 100%;
    height: 100%;
    box-sizing: content-box;
}

.on-camera-off {
    width: 50%;
    margin: 25% auto;
    box-sizing: content-box;
}

.on-camera-off i {
    color: #343434;
    margin-top: 200px !important;
    padding: 8px;
}

.video-container-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    width: 250px;
   
}

.video-container-btn button {
    width: 100%;
    color: #fff !important;
    background: var(--primary-color) !important;
    border-radius: 4px !important;
    margin-right: 20px !important;
}

@media (max-width: 600px) { 
   #container-box {
     width: 100%;
     display: flex;
    flex-direction: column;
   }

   #container-box .video-container-box__items {
     width: 100%;
   }

   .video-container__content {
        width: 100%;
        padding: 8px;
        
   }

   .video-item__contents {
     width: 100%;
   }

   .video-item__contents .video-item__content-form {
     width: 100%;
     margin: 0;
   }

 }

