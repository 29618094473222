.send-money__no-data-page {
    width: 70%;
    margin: 20% auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #1ab2ff;
}

.send-money__no-data-page span {
    text-align: center;
}

.oval-shape {
    border-radius: 50%;
    background: #1ab3ff1b;
    height: 150px;
    width: 150px;
    align-self: center;
    margin-bottom: 20px;
}

.send-money__data-page {
    width: 70%;
    margin: 2% auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    background: white;
    border: 1px solid #dedede;
    padding: 12px;
}

.send-money__data-page__items {
    padding: 8px;
    display: flex;
    flex-direction: column;
}
.send-money__data-page__items .title{
    text-align: left;
    font-weight: 600;
    font-size: 14px;
    margin-left: 5px;
}

.send-money__data-page__items .items_box {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #9a9a9a;
}

.item_box-value {
    color: #333;
}

.top-right__items {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.send-form__left {
    float: left;
}

.send-form__right {
    float: right;
    margin-left: 30px;
}

.record-money-form {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.display-inmate-recorded {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 30px;
}

.add-btn-list {
    width: 100%;
    margin-top: 30px;
    color: white;
    background:  #282936;
}

.add-btn-list:focus{
    border: none;
    color:  white !important;
}

.add-btn-list:hover {
    color:  white !important;
}

.save-btn {
    width: 200px;
    background: #282936;
    color: #fff;
    float: left;
    margin-top: 20px;
}

.save-btn:focus{
    border: none;
    color:  white !important;
}

.save-btn:hover {
    color:  white !important;
}


.goto {
    width: 200px;
    background: #282936;
    color: #fff;
    margin-top: 20px;
    align-self: center;
}

.goto:focus{
    border: none;
    color:  white !important;
}

.goto:hover {
    color:  white !important;
}

#transparent-btn {
    background: transparent !important;
    border: none !important;
    color: #333 !important;
    font-weight: 400 !important;
    line-height: none !important;
    border-radius: none !important;
    box-shadow: none !important;
    font-size: 16px;
}

.notification-content {
    padding: 4px;
}