.video-start-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    bottom: 0;
    top: 0;
    position: absolute;
    background: var(--primary-color);
}

.context-box {
    margin: 10% auto;
    padding: 16px;
}

.context-box h2 {
    font-weight: 600;
    font-size: 55px;
    color: var(--white-color);
    
}

.context-box .subtitle-msg {
    color: var(--secondary-color);
    font-weight: 600;
    font-size: 20px;
    margin: 12px;
}

.spacer {
    width: 100%;
    height: 1px;
    margin-top: 12px;
    margin-bottom: 12px;
    background-color: var(--secondary-color);
}

.guest-info {
    font-size: 24px;
    color: var(--white-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.guest-info span {
    margin: 12px;
}

.guest-info-title {
    color: var(--secondary-color);
    font-weight: 800;
}

.time-countdown {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    justify-content: center;
    width: 100%;
}

.time-countdown span {
    font-size: 55px;
    color: var(--white-color);
}