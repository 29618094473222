.inmate-dashboard {
  background-color: #f5f7fa;
}

.role-filter {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.search-controls {
  min-width: 320px;
}

.search-icon {
  color: #bfbfbf;
}

.stats-container {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.stat-card {
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-label {
  font-size: 13px;
  margin-bottom: 4px;
  color: #8c8c8c;
}

.stat-value {
  font-size: 18px;
  font-weight: 600;
}

.stats-divider {
  height: 36px;
  margin: 0;
}

.table-container {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.inmate-table .ant-table-thead > tr > th {
  background-color: #fafafa;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #f0f0f0;
}

.inmate-table .ant-table-tbody > tr:hover > td {
  background-color: #f5f7fa;
}

.inmate-table .ant-empty {
  padding: 40px 0;
}

@media (max-width: 768px) {
  .search-controls {
    min-width: 100%;
  }

  .stat-card {
    padding: 10px 12px;
  }

  .stat-value {
    font-size: 16px;
  }
}

/* Add to your CSS file */

/* Action column styling */
.action-cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.primary-action-btn {
  margin-right: 4px;
  height: 24px;
  width: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-dropdown-btn {
  height: 24px;
  width: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

/* Menu styling */
.action-dropdown-menu {
  min-width: 160px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.action-dropdown-menu .ant-dropdown-menu-item {
  padding: 8px 12px;
}

.ant-dropdown-menu-item .anticon {
  font-size: 14px;
  margin-right: 8px;
}

/* Status icons */
.critical-icon {
  color: #ff4d4f;
}

.normal-icon {
  color: #52c41a;
}

.lock-icon {
  color: #ff4d4f;
}

.warning-icon {
  color: #faad14;
  margin-right: 6px;
}

.ant-tag {
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 500;
}

.ant-table-thead > tr > th {
  background-color: #fafafa;
  font-weight: 500;
}

.ant-table-tbody > tr:hover > td {
  background-color: #f5f7fa;
}

.ant-popover-inner-content {
  padding: 12px 16px;
}

.ant-popover-message {
  font-size: 14px;
}

.ant-popover-buttons {
  margin-top: 12px;
}

@media (max-width: 768px) {
  .primary-action-btn,
  .action-dropdown-btn {
    height: 22px;
    width: 22px;
  }
}
