.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    background: #140E0C;
}

.container-tiny_top-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px;
    background: #282936;
}

.container-tiny_top-bar span {
    color: #D1D1D1;
    margin-left: 5px;
    margin-right: 5px;
}

.container-tiny_top-bar a {
    margin-left: 5px;
    margin-right: 5px;
}

.container-tiny_top-bar a span {
    color: #D1D1D1;
   
}

.container-box {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    
}

.container-box__items {
    display: flex;
    flex-direction: row;
    margin: 0% auto;
    background: #D4C79D;
    width: 100%;
}

.container-box__left {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 5% auto;
    text-align: center;
    justify-content: center;
}

.container-box__left  .title-msg {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
}

.container-box__left-item {
    width: 80%;
    margin: 10% auto;
    display: flex;
    flex-direction: column;
}

.home-message-title {
    font-weight: 600;
     font-size: 60px;  
     color: #000;
}

.home-message-subtitle {
    font-weight: 600;
    font-size: 30px;
    color: #333;
    margin-top: 20px;
    
}


.btn-groups {
    margin-top: -20px;
    padding: 8px;
    justify-content: center;
   padding: 55px;
}

.btn-groups a {
    color: #fff !important;
    margin:  15px !important;
    padding: 12px !important;
    flex-direction: row;
    border-radius: 30px;
    text-align: center;
    background: transparent;
    width: 60%;
    margin-left: -100px !important;
    align-self: center;
    padding: 10px;
    transition: all .35s;
    border: 2px solid #140E0C;
    background: #140E0C;
}

.btn-groups a  span {
    font-weight: bold;
    font-size: 16px !important;
    padding: 55px;
    text-align: center;
}


.btn-groups button {
    color: #fff !important;
    flex-direction: row;
    border-radius: 30px !important;
    width: 60%;
    border: 1px solid #140E0C;
    background: #140E0C;
    font-weight: bold;
    font-size: 16px !important;
    margin:  10px !important;
}

.btn-groups button:focus {
    border: none !important;
}

.btn-groups button:hover {
    color: black !important;
    border: none !important;
}

.btn-groups a  i {
    font-size: 25px !important;
    margin-top: 2px !important;
}

.container-box__right {
    width: 40%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    /* background-color: #E3E2DF; */
    border-radius: 15px 0px 0px 500px;
}

.widget-options {
    width: 50%; 
    margin: 10% auto;
}

.widget-options img {
    margin-left: 100px;
    margin-top: 80px;
    /* width: 500px !important;
    height: 500px !important; */
}

.widget-options-form {
    width: 350px;
    display: flex;
    flex-direction: column;
    z-index: 0.99999;
    background: #ebe9eb;
    padding: 22px;
    border-radius: 12px;
}

.widget-options-form h2 {
    font-size: 22px;
    font-weight: 500;
}


.widget-options-form__items {
    display: flex;
    flex-direction: column;
    margin: 5px;
    color: #9c9c9c;
}

.widget-options-form__items span {
    text-align: left;
}

.widget-options-form__items button {
    margin-top: 20px !important;
}

.right-content__row a {
    align-items: center;
}

.link-ground-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.left-content .left-content__row {
    align-items: center;
  
}

.left-content .left-content__row a h2 {
    margin-top: 10px;
    font-size: 30px;
    margin-top: 10px !important;
}

.container-box__items-middle {
    background: #D4C79D;
    width: 100%;
    height: 100%;
    padding: 8px;
    position: absolute;
    overflow-y: auto;
}

.page-title {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.page-title span {
    color: rgba(0,0,0,.87);
    font-weight: 600;
    font-size: 18px;
}

.underline-widget {
    width: 150px;
    background: #fff;
    height: 2px;
    margin-top: 10px;
    align-self: center;
}

.landing-page-form {
    margin-top: -50px;
    background: inherit;
}


@media (max-width: 375px) {
    .container-box__items {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .container-box__left {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .btn-groups {
        padding: 8px;
        justify-content: center;
        margin-left: 0px !important;
    }

    .btn-groups button {
        width: 100%;
        text-align: center !important;
    }
}




@media only screen and (max-width: 600px) {
    
    .container {
        width: 100%;
        display: block;
        flex-direction: column;
    }
    .container-box {
        width: 100%;
    }
    .container-box__items {
        width: 100%;
        display: block;
        flex-direction: column;
        
    }

    .container-box__left {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 16px;
        margin: 0;
    }
    .title-msg {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 30px;
    }

    .title-msg h2 {
        text-align: center;
        margin: 0;
        padding: 16px;
    }

    .title-msg span {
        text-align: center !important;
        width: 100%;
        line-height: 30px;
        text-align: center;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 20px;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.5;
        word-wrap: break-word;
    }

    .btn-groups {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        margin-top: 40px;
        margin-bottom: 55px;
        width: 100%;
    }
    .btn-groups a {
        margin:  10px !important;
    }
    

    .container-box__right {
        display: none;
    }

    .container-box__items {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .container-box__left {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .btn-groups {
        padding: 8px;
        justify-content: center;
        margin-left: 0px !important;
    }

    .btn-groups button {
        width: 100%;
        text-align: center !important;
    }
  }

  