.coupon-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
  background-color: #f9f9f9;
  font-family: "Arial, sans-serif";
}

.coupon-header,
.coupon-footer {
  text-align: center;
  margin-bottom: 20px;
}

.coupon-header span {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.coupon-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.coupon-item:last-child {
  border-bottom: none;
}

.coupon-item span {
  font-size: 16px;
  color: #555;
}

.coupon-item b {
  color: #000;
}

.coupon-balance {
  color: green;
  font-size: 16px;
  font-weight: bold;
}

.coupon-table {
  margin-top: 20px;
}

.no-result-found {
  text-align: center;
  font-style: italic;
  color: #999;
}

@media print {
  .no-print {
    display: none;
  }

  .coupon-container {
    border: none;
    box-shadow: none;
    margin: 0;
    width: 100%;
    page-break-inside: avoid;
  }

  .coupon-container * {
    color: black !important;
  }

  .coupon-header,
  .coupon-footer {
    text-align: center;
    margin: 0;
    padding: 10px 0;
  }

  .coupon-item {
    margin-bottom: 10px;
    padding: 8px 0;
    border-bottom: 1px solid #eee;
  }

  .coupon-item:last-child {
    border-bottom: none;
  }

  .coupon-table {
    margin-top: 20px;
  }

  .no-result-found {
    text-align: center;
    font-style: italic;
    color: #999;
  }
}
