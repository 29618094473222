.row {
    display: flex;
    flex-direction: row;
}

.row-left__items {
    padding: 8px;
    width: 100%;
    width: 10%;
    border-right: 1px solid #eaeaea8d;
    float: right;
    background: #f3f2f2d6;
    
}

.row-left__btn-group {
    display: flex;
    height: 100%; 
    position: absolute;
    flex-direction: column;
}   

.active-link {
    font-size: 18;
    font-weight: 600;
    color: #333;

}

.row-left__items a {
    padding: 8px;
    margin-top: 8px;
    width: 100%;
    text-align: start;
    color: #333;
    
}

.row-left__items a:hover {
    color: #7d7d7d;
}

.row-right__items {
    width: 92%;
    height: 900px;
    position: relative;
    background: #fff;
   
}

.empty-data {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20% auto;
}

.empty-data img {
    width: 300px;
    height: 300px;
}

.empty-data span {
    margin-left: 40px;
    align-self: center;
    font-size: 18px;
    font-weight: 600;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 800px;
    padding: 8px;
    margin-top: 20px;
}

.column h4 {
    text-align: left;
    margin-left: 55px;
    margin-top: 20px;
    font-size: 18px;
}

.column-box {
    /* margin: 0 auto; */
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin-left: 30px;
}

.column-box .form__input {
    text-align: start;
    margin: 5px;
    width: 350px;
}


.column-box .form__input span {
    color: #333;
}

.form__input input {
    padding: 8px;
}

.form_button {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 20px;
    width: 350px;
}

.form_button button {
    color: #fff !important;
    background: #262d30 !important;
}

.form_button button:hover {
    color: #fff !important;
    border: 1px solid #262d30 !important;
}

.right-btn-group a  {
    color: red;
    background: transparent;
    padding: 8px;
    margin-right: 20px;
}

.right-btn-group a:hover {
    cursor: pointer;
    color: #f95f5f;
}