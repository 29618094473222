.row__box {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 8px;
    justify-content: space-between;
}
.input-form__column {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 600px;
    padding: 8px;
    background: #FFFF;
    border-radius: 4px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.table-form__column {
    display: flex;
    flex-direction: column;
    width: 58%;
    padding: 8px;
    background: #FFFF;
    border-radius: 4px;
    justify-content: space-between;
}

.list-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    background: #f2f2f2;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-top: 8px;
}

.empty-box {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.empty {
    font-size: 18px;
    font-size: 400;
    align-self: center;
}

.list-title .icon {
    align-self: center;
}

.list-title .icon:hover {
    cursor: pointer !important;
}

.list-title__title {
    font-weight: 400;
    font-size: 18px;
    margin: 5px;
}

.list-title__subtitle {
    display: flex;
    flex-direction: row;
}

.list-title__subtitle span {
    font-weight: 400;
    font-size: 16px;
    margin: 5px;
}

.input-form__column .input-box {
    display: flex;
    flex-direction: column;
    margin: 5px;
    float: left;
    padding: 5px;
}

.input-box .input-label {
    float: left;
    text-align: left;
    margin-bottom: 5px;
}

.input-box input{
    width: 100% !important;
}

.input-box button {
    margin-top: 20px;
    background: #282936 !important;
    color:  #FFFF;
    font-weight: bold;
    width: 100% !important;
}

.input-box button:hover {
   color:   #FFFF !important;
   border: 1px solid #282936 !important;
}

.icon-btn-right {
    display: flex;
    flex-direction: row;
    width: 15%;
    justify-content: space-between;
    padding-right: 10px;
}

.icon-switch-btn {
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.icon-switch-btn span {
    color: #656565;
    align-self: center;
    margin-right: 10px;
}