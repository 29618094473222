/* .container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
}

.container-box {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  display: block;
  text-align: center;
}

.sized-box {
  height: 1rem;
}

.login-form__input {
  margin-bottom: 1rem;
}

.login-form__input span {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.input-group {
  margin-bottom: 1rem;
}

.password-requirements {
  font-size: 0.875rem;
  color: #666;
  margin-bottom: 1rem;
}

.special-chars {
  display: block;
  color: #1890ff;
  margin-top: 0.25rem;
} */

.update-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
}

.update-password-card {
  background: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.update-password-title {
  text-align: center;
  margin-bottom: 24px;
}

.update-password-steps {
  margin-bottom: 24px;
}

.update-password-form {
  .ant-form-item {
    margin-bottom: 16px;
  }
}

.password-requirements {
  display: block;
  margin-top: 16px;
  font-size: 12px;

  .special-chars {
    color: #1890ff;
  }
}
