.edit-container {
    width: 60%;
    margin: 2% auto;
}

.edit-container__box {
    margin: 0% auto;
    width: 60%;
    border-radius: 8px;
    border-bottom: 1px solid #8686;
    background: rgb(240, 240, 240);
    padding: 16px;
}

.edit-container__box  {
    text-align: left;

}

.date-selection {
    margin-left: 5px;
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.token-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.custom-modal-btn-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.custom-modal-btn-group .custom-btn {
   text-align: start;
   border-color: 1px solid #8686;
   background: #e7e7e7;
   border-radius: 4px;
   margin: 5px;
   padding: 14px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}

.custom-modal-btn-group .custom-btn:hover {
    cursor: pointer;
}

.custom-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 30px;
    padding-right: 8px;
}