.dashboard-container__row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

.dashboard-container__left {
  flex: 0 0 18%;
  background-color: #031527;
  border-right: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.dashboard-container__left::-webkit-scrollbar {
  display: none;
}

.dashboard-container__left {
  scrollbar-width: none;
}

.dashboard-container__left {
  -ms-overflow-style: none;
}

.nav-bar__left {
  flex: 1;
  overflow-y: auto;
}

.dashboard-container__right {
  flex: 1;
  background-color: #e5e4e2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
}

.dashboard-container__box {
  flex: 1;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .dashboard-container__row {
    flex-direction: column;
  }

  .dashboard-container__left {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #dedede;
  }

  .dashboard-container__right {
    flex: 1;
    margin-left: 0;
  }
}

.top-items {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding: 25px;
  justify-content: space-between;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  padding: 16px;
  box-sizing: border-box;
}

.card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  background-color: #fff;
  box-sizing: border-box;
  transition: transform 0.3s ease;
}

.card-container:hover {
  transform: translateY(-5px);
}

.card-container__box {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.card-container__box-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 100%;
}

@media (max-width: 1200px) {
  .card-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .card-grid {
    grid-template-columns: 1fr;
  }
}

.bottom-box__item {
  display: flex;
  flex-direction: column;
  padding: 8px;
  text-align: center;
}

.box-title {
  font-weight: 500;
  font-size: 14px;
  color: #686661;
}

.box-value {
  color: rgb(52, 54, 87);
  font-weight: 500;
  font-size: 20px;
  margin-top: 10px;
}

.card-container_left-item {
  display: flex;
  flex-direction: column;
  color: rgb(52, 54, 87);
  width: 85%;
  padding: 8px;
  text-align: start;
}

.card-container_left-item .title-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-container_left-item span {
  font-size: 16px;
  font-weight: 500;
  padding-top: 8px;
  color: rgb(52, 54, 87);
}

.card-container_left-item h2 {
  font-size: 25px;
  color: rgb(52, 54, 87);
  font-weight: 300;
  font-family: "Poppins", sans-serif;
}

.card-container_right-item {
  color: #333;
  align-self: center;
  align-self: start;
}

.card-container_right-item .icons {
  padding: 8px;
  width: 50px;
  height: 50px;
  background-color: #98989839;
  font-size: 18px;
  border-radius: 4px;
}

.card-container_right-item .icons i {
  align-self: center;
  margin-top: 2px;
  color: #4d4d4d;
}

.middle-items {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  margin-bottom: 100px;
  padding: 25px;
  justify-content: space-between;
}
.middle-items_left-box {
  width: 50%;
  flex-grow: 1;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.middle-items_left {
  width: 48%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  background-color: #fff;
}

.middle-items_left-top {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-bottom: 1px solid #eee;
}

.middle-items_left-top .title-icon {
  display: flex;
  flex-direction: row;
}

.middle-items_left-top .title-icon i {
  align-self: center;
  font-size: 14px;
  margin-top: 8px;
}

.middle-items_left-top .title-icon span {
  align-self: center;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: rgb(52, 54, 87);
}

.middle-items_left-top span {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin-top: 10px;
}

.middle-items_left-bottom {
  width: 100%;
  background-color: #fff;
}

.alert-message {
  padding: 20px;
}

.alert-message span {
  color: rgb(236, 14, 14);
  font-size: 16px;
  text-align: center;
  align-content: center;
  margin-top: 20px;
  font-weight: bold;
}

.middle-items_right {
  width: 70%;
  border-radius: 10px;
  display: flex;
  padding: 16px;
  margin-top: 5px;
  flex-direction: column;
  border: 1px solid #eee;
  justify-content: center;
  background-color: #fff;
  height: 400px;
}

.bottom-items {
  width: 96%;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  margin-left: 30px;
  justify-content: space-between;
}

.bottom-items__left {
  width: 48%;
  height: 50%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px 1px #eee;
  background-color: #fff;
}

.separator {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}

.bottom-items_left-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.bottom-items_left-top span {
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  margin-top: 10px;
}

.bottom-items_left-bottom {
  width: 100%;
}

.appointment-list {
  padding: 20px;
}

.appoint-items {
  display: flex;
  flex-direction: row;
}

.appoint-items i {
  align-self: center;
}

.appoint-items span {
  font-size: 16px;
  text-align: start;
  margin-top: 5px;
  font-weight: bold;
  margin-left: 10px;
}

.bottom-items__right {
  width: 48%;
  height: 50%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px 1px #eee;
  background-color: #fff;
}

.user-account-tbl {
  width: 100%;
  height: 95%;
  right: 0;
  bottom: 0;
  top: 50px;
  background: #f5f5f5;
  /* border: 1px solid #D8D8D8; */
}

.user-account-tbl h2 {
  text-align: left;
  margin-left: 20px;
  font-size: 18px;
}

.left-container-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.tbl-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  background: #fff;
  border-bottom: 1px solid #d8d8d8;
  align-items: center;
}

.tbl-items .tbl-items-title {
  align-self: center;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
}

.tbl-items__col {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 8px;
}

.separator__col {
  height: 60px;
  width: 1px;
  background: #d8d8d8;
  margin-left: 55px;
  margin-right: 55px;
}

.tbl-items button {
  text-align: center !important;
  margin-right: 30px !important;
  border: 2px solid#262D30 !important;
  color: #fff !important;
  background: #282936 !important;
}

.tbl-items .back-btn {
  display: flex;
  flex-direction: row;
  justify-content: start;
  background: none !important;
  border: none !important;
  float: left;
  color: #333 !important;
  box-shadow: none !important;
}

.tbl-items .back-btn span {
  align-self: center;
  margin-left: 5px;
}

.form-input {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 5px;
}

.form-input span {
  margin-bottom: 5px;
}

.on-submit__btn {
  background: #262d30 !important;
  color: #fff !important;
}

.on-submit__btn:hover {
  border: 1px solid #262d30 !important;
  color: #d8d8d8;
}

.card-container-tbl {
  width: 350px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  justify-content: space-between;
  margin: 30px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
}

.card-container-tbl_left-item {
  display: flex;
  flex-direction: column;
  color: #333;
  align-content: center;
  width: 80%;
  align-self: center;
  text-align: left;
  padding: 10px;
}

.card-container-tbl_left-item span {
  font-size: 14px;
  font-weight: 500;
}

.card-container-tbl_left-item h2 {
  font-size: 16px;
  font-weight: 500;
}

.card-container-tbl_right-item {
  color: #333;
  align-self: center;
}

.card-container-tbl_right-item .icons {
  padding: 8px;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.225);
  font-size: 18px;
  border-radius: 10px;
}

.card-container-tbl_right-item .icons i {
  align-self: center;
  margin-top: 2px;
}

.last-appointment__row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  border-bottom: 1px solid #8686;
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.last-appointment__row:hover {
  cursor: pointer;
  background-color: rgb(249, 249, 249);
}

.last-appointment__row span {
  margin-top: 5px;
  font-weight: 300;
}

.loading-content {
  width: 70%;
  height: 800px;
  align-self: center;
  margin: 20% auto;
}

.appointment-details {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* visit details */

.visit-details__content {
  width: 40%;
  margin: 2% auto;
  border-radius: 8px;
  padding: 8px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
}

.visit-details__content-box {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.visit-details__content-box .title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid #8686;
  padding-top: 10px;
  padding-bottom: 10px;
}

.visit-details__content-box .title h4 {
  text-align: left;
  font-size: 16px;
  margin-bottom: 10px;
  align-self: center;
  margin-left: 20px;
  margin-top: 5px;
}

.visit-details__content-box .content-box__column {
  display: flex;
  flex-direction: column;
}

.visit-details__content-box .content-box__column span {
  text-align: left;
  margin-bottom: 10px;
}
.item-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#items-rows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 12px;
}

#items-rows .telco-names {
  font-weight: 500;
  font-size: 16px;
  color: #686661;
  font-family: "Poppins", sans-serif;
}

#items-rows .telco-amount {
  font-size: 16px;
  color: rgb(52, 54, 87);
}

.btn-group {
  width: 100%;
  margin: 0px auto;
  background: #f0f0f0;
  justify-content: flex-end;
}

.btn-group__row {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  border-bottom: 1px solid #dedede;
}

.btn-items {
  display: flex;
  flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #f0f0f0;
  padding-left: 20px;
  padding-right: 10px;
  margin: 10px;
}

.btn-items span {
  font-size: 14px;
  font-weight: 500;
  align-self: center;
}

.custom-spacer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.modal-cancel-button {
  background: #cf1322 !important;
  color: #ffff !important;
}

.modal-save-button {
  background: #282936 !important;
  color: #ffff !important;
}

.modal-save-button:hover {
  border: none !important;
}

.model-button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.model-button-group .stay-in-btn {
  width: 40%;
  background: #05386b;
  color: white;
}

.model-button-group button {
  color: black;
}
