/* #container-box {
    background-color: #fff !important;
    height: 100vh;
    position: absolute;
    width: 100%;
    overflow: hidden;
}

.login-container-box__items {
    display: flex;
    flex-direction: column;
    margin: 5% auto;
    background-color: transparent;
    width: 40%;
    height: 650px;
    border: none;
    padding: 8px;
}

.item__contents .login-title {
    text-align: left;
    width: 100%;
}
.item__contents .login-title,
.login-container-box__items .login-form__input span {
    text-align: start;
    font-size: 18px;
    font-weight: 800;
    color: #fff;
    margin: 15px 0 0 0;
}

.login-container-box__items .login-form__input span {
    font-size: 14px;
    font-weight: 500;
}

.item__contents {
    width: 85%;
    margin: 10% auto;
    background-color: #282936c7;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #05386B;
}



.item__contents a {
    color: rgb(0, 179, 255);
    text-align: right;
    font-size: 14px;
    text-decoration: underline;
    display: block;
    margin-top: 10px;
}

.sized-box {
    height: 55px;
}

.login-form__input {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    padding: 0;
}

.login-form__input span {
    font-size: 14px;
    margin-bottom: 10px;
}

#login-btn {
    background-color: #fff !important;
    color: #333 !important;
}

.login-form__input .go-to-web__btn {
    text-align: right;
    margin: 10px 0;
    text-decoration: none;
}

.login-btn {
    margin-top: 30px;
}

.input-group {
    display: flex;
    flex-direction: row;
} */
/* LoginPage.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f2f5;
  }
  
  .login-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 32px;
    width: 100%;
    max-width: 400px;
  }
  
  .login-title {
    margin-bottom: 24px !important;
    text-align: center;
  }
  
  .login-form {
    margin-top: 24px;
  }
  
  .forgot-password-container {
    margin-bottom: 16px;
  }
  
  .client-site-link {
    margin-top: 16px;
  }
  
  @media (max-width: 480px) {
    .login-card {
      padding: 24px;
    }
  }