.side-bar__container {
    display: flex;
    flex-direction: column;
    width: inherit; 
    overflow-y: auto;
    box-sizing: border-box;
    background: #031527;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}


.side-bar__container::-webkit-scrollbar {
    width: 4px;
}

.side-bar__container::-webkit-scrollbar-track {
    background: transparent; 
}

.side-bar__container::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px; 
}


.side-bar__container-box-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box; 
 
}

.side-bar__container-box-content a {
    color: #F7F1F0;
    justify-content: left;
}

.side-bar__container-box-content span {
    color: #F7F1F0;
}

.img-content__item {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    padding: 20px;
    overflow: hidden;
}

.img-content__item span {
    font-size: 30px;
    font-weight: 600;
    align-self: center;
    text-align: center;
    color: #F7F1F0;

}

.img-content__item img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
}

.main-link {
    display: flex;
    flex-direction: column;
}

.sub-nav {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
}

.sub-nav a {
    margin-bottom: 5px;
}

.active {
    background: #7575751e;
    margin-bottom: 5px;
}

.active {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: #ffff !important;
    padding: 15px;
    font-weight: 600;
}

.active span {
    color: #ffff !important;
    margin-left: 15px;
    align-self: center;
}

.btn-right-items {
    display: flex;
    flex-direction: row;
    width: 90%;
}

.right-icon {
    width: 10%;
}

.right-icon i {
   align-self: flex-end;
   right: 0;
}

.logout-btn {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    align-items: center;
}

.logout-btn .logout-icon {
    color: #F7F1F0 !important;
}

.logout-btn span {
    margin-left: 15px;
}

.logout-btn:focus {
    border: none !important;
}

.side-bar__container-box-content .ant-menu-item, 
.side-bar__container-box-content .ant-menu-submenu-title {
    text-align: left !important;
    width: 100% !important; 
}

.side-bar__container-box-content .ant-menu-item a,
.side-bar__container-box-content .ant-menu-submenu-title {
    display: block !important; 
    text-align: left !important; 
}

/* .ant-menu-dark .ant-menu-item-selected {
    background-color: #1a1b2e !important;
} */

.Icon-group {
    text-align: left !important;
}
