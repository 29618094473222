.column-box {
    height: 800px;
    width: 100%;
}

.column-box-large {
    height: 800px;
    width: 100%;
    padding: 16px;
}

.user-card {
    width: 100%;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
}

.user-card__box {
    flex-direction: row;
    justify-content: space-between;
    border-radius: 8px;
    background: #fff;
    width: 100%;
    display: flex;
    padding: 30px;
    margin-bottom: 10px;
}

.user-card__left {
    display: flex;
    flex-direction: row;
    margin: 5px;
}

#inmate-name {
    align-self: center;
    margin-left: 20px;
}

.user-card__right {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.user-card__right span {
    color: #333;
    align-items: center;
    margin-right: 60px;
    margin-top: 10px;
}

.user-card__right a {
    align-items: center;
    margin-left: 10px;
    margin-top: 10px;
}

.order-box {
    width: 100%;
    height: 100%;
    padding: 16px;
}

.order-box {
    padding: 8px;
    background: #fff;
}

.order-box__top {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.order-box__bottom {
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.close:hover {
    cursor: pointer;
}

.drawer-row__form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.drawer-row__form-cal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.calculation-era {
    height: 300px;
    width: 100%;
    overflow: scroll;
    border-bottom: 1px solid rgb(175, 175, 175);
    margin-bottom: 30px;
    margin-top: 20px;
}

.calculation-era__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.calculation-era__title #value {
    text-align: left;
}

.totalAmount {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
}

.order-file__card {
    width: 100%;
    border-radius: 12px;
    background: #fff;
    border: 1px solid #e9e9e9;
    margin-bottom: 10px;
}

.order-file__card:hover {
    cursor: pointer;
}

.order-file__card-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.order-file__card-content-left {
    float: left;
    margin: 8px;
    padding: 8px;
    display: flex;
    flex-direction: row;
}

.order-file__card-content-left span {
    margin-left: 20px;
    align-self: center;
    font-size: 14px;
}

.order-file__card-content-right {
    float: right;
    font-size: 18px;
    margin: 8px;
    padding: 8px;
}

.file-items {
    display: flex;
    flex-direction: column;
}

.file-items span {
    font-size: 14px;
    color: #d0d0d0;
    margin-top: 10px;

}

.title-box {
    width: 100%;
    align-content: flex-start;
    text-align: left;
}

.title-box #title {
    color: #333;
}

.reject-form {
    display: flex;
    flex-direction: column;
}

.reject-form .btn-group {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100% !important;
    background: transparent;
}

.print-users {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    padding: 8px;
    margin-top: 30px;
}

.steps-box {
    padding: 5px; 
    margin-left: 20px;
    width: 200px;
    height: 800px;
}

:where(.css-dev-only-do-not-override-33f5ww).ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: #282936;
    border-color: #282936;
}


:where(.css-dev-only-do-not-override-33f5ww).ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #282936;
}

:where(.css-dev-only-do-not-override-33f5ww).ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #282936;
}

.tbl-group-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
  }
  
.fixed-size-image {
    width: 100%;
    height: 500px; 
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}