.invoice-container {
  background: #f5f7fa;
  min-height: 100vh;
  padding: 16px;
}

.header-card {
  margin-bottom: 16px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
}

.summary-card {
  margin-bottom: 16px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
}

.table-card {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
}

.table-header {
  margin-bottom: 16px;
}

.invoice-table {
  margin-top: 8px;
}

.invoice-table .ant-table-thead > tr > th {
  background-color: #fafafa;
  font-weight: 600;
}

.paid-row {
  background-color: rgba(82, 196, 26, 0.05);
}

.unpaid-row {
  background-color: rgba(245, 34, 45, 0.05);
}

.invoice-details-drawer .ant-drawer-header {
  border-bottom: 1px solid #f0f0f0;
}

.details-table .ant-table-thead > tr > th {
  background-color: #fafafa;
  font-weight: 600;
}
