.video-call-app {
  font-family: Arial, sans-serif;
  color: #333;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #202124;
}

.video-call-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #3c4043;
  color: white;
}

.controls {
  display: flex;
  gap: 10px;
}

.control-button {
  padding: 10px 15px;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.control-button:hover {
  background-color: #1557b0;
}

.control-button.muted,
.control-button.camera-off {
  background-color: #f44336;
}

.control-button.leave-button {
  background-color: #e74c3c;
}

.control-button.leave-button:hover {
  background-color: #c0392b;
}

/* Guest view styles */
.video-container {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #202124;
  flex: 1;
  position: relative;
}

.remote-video-box {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #444;
  border-radius: 8px;
}

.local-video-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25%;
  height: 30%;
  background-color: #555;
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid white;
  z-index: 1;
}

/* Observer view styles */
.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
  padding: 16px;
  flex: 1;
  overflow: auto;
}

.video-grid-item {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-color: #444;
  border-radius: 8px;
  overflow: hidden;
}

.video-feed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-name {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 2;
}

/* .video-controls {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 8px;
  z-index: 2;
} */

.control-icon {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-size: 16px;
  padding: 0;
  transition: background-color 0.3s ease;
}

.control-icon:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.control-icon.muted,
.control-icon.camera-off {
  background-color: #f44336;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .video-grid {
    gap: 8px;
    padding: 8px;
  }

  .control-button {
    padding: 8px 12px;
    font-size: 14px;
  }
}

.user-id {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
}

.observer-info {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.user-name {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

/* ----&&& */
.video-call-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.video-call-header {
  padding: 1rem;
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.video-container {
  flex: 1;
  position: relative;
  background: #000;
  min-height: 0;
}

.remote-video-box {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1a1a1a;
}

.local-video-overlay {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 240px;
  height: 135px; /* 16:9 aspect ratio */
  background: #2a2a2a;
  border-radius: 8px;
  overflow: hidden;
}

/* Grid layout for observer mode */
.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 1rem;
  padding: 1rem;
  height: calc(100vh - 80px);
  background: #1a1a1a;
}

.video-grid-item {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  background: #2a2a2a;
  border-radius: 8px;
  overflow: hidden;
}

.video-feed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Make sure the video elements maintain aspect ratio */
.video-feed video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.user-name {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  z-index: 10;
}

.video-controls {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
  z-index: 10;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.control-icon {
  background: rgba(0, 0, 0, 0.7);
  border: none;
  color: white;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-icon.muted,
.control-icon.camera-off {
  background: rgba(255, 0, 0, 0.7);
}

.observer-info {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  z-index: 10;
}

.user-id {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
}

/* Control buttons in header */
.controls {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.control-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: #007bff;
  color: white;
  font-size: 0.9rem;
}

.control-button.muted,
.control-button.camera-off {
  background: #dc3545;
}

.control-button.leave-button {
  background: #dc3545;
}

.control-button:hover {
  opacity: 0.9;
}

.guest-name {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

.guest-title {
  color: #fff;
  font-size: 20px;
}
