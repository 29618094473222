.show-details__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.show-details__row #title {
    font-size: 14px;
    font-weight: 400;
}

.facility_summary_tbl {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 12px;
    padding: 12px;
}

.facility_summary_tbl-right {
    width: 30%;
}

.facility_summary_tbl-left{
    width: 70%;
}


.empty-list {
    display: flex;
    flex-direction:column;
    justify-content: center;
    margin: 20% auto;
}

.empty-list img {
    width: 200px !important;
}

.empty-list__title {
    margin-top: 30px;
    font-size: 25px;
    font-weight: 400;
}


.empty-list__subtitle {
    font-size: 16px;
    font-weight: 500;
    color: #bdbdbd;
    margin-top: 20px;
}